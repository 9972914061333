<template>
  <div class="page-content">
    <Breadcrumb/>
    <SearchEngine
        v-if="isAdmin"
        class="mb-3"
    />
    <div class="detail-page balance-history-page">
      <div class="detail-page__left"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['payments_currentBalance', 'payments_withdrowedTotally', 'payments_AddMoneySum', 'payments_earnedTotally'])"></span>

        <CardLeftBlock class="balance-history-page__left-block"
                       :backgroundImage="'balance'"
                       :name="$t('payments_currentBalance.localization_value.value')"
                       :value="'$' + parseFloat($store.getters.getBalanceHistoryCommonList.balance.user_balance).toFixed(2)"
                       v-if="Object.hasOwnProperty.call($store.getters.getBalanceHistoryCommonList, 'balance')"
        >
          <template slot="body">
            <div class="fragment d-flex flex-column">
<!--              <div class="change-balance-line">-->
<!--                {{$t('payments_withdrowedTotally.localization_value.value')}}-->
<!--                <span>-->
<!--                  $ {{parseFloat($store.getters.getBalanceHistoryCommonList.balance.total_remove).toFixed(2)}}-->
<!--                </span>-->
<!--              </div>-->
<!--              <div class="change-balance-line">-->
<!--                {{$t('payments_AddMoneySum.localization_value.value')}}-->
<!--                <span>-->
<!--                  $ {{parseFloat($store.getters.getBalanceHistoryCommonList.balance.total_add).toFixed(2)}}-->
<!--                </span>-->
<!--              </div>-->
            </div>
          </template>
        </CardLeftBlock>
        <CardLeftBlock class="balance-history-page__left-block"
                       v-else
                       :backgroundImage="'balance'"
                       :name="$t('payments_currentBalance.localization_value.value')"
                       :value="'$' + 0"
        >
          <template slot="body">
            <div class="fragment d-flex flex-column"
                 v-if="Object.hasOwnProperty.call($store.getters.getBalanceHistoryCommonList, 'balance')">
              <div class="change-balance-line">
                {{$t('payments_withdrowedTotally.localization_value.value')}}
                <span>
                  $ 0
                </span>
              </div>
              <div class="change-balance-line">
                {{$t('payments_earnedTotally.localization_value.value')}}
                <span>
                  $ 0
                </span>
              </div>
            </div>
          </template>
        </CardLeftBlock>
      </div>
      <div class="detail-page__right">
        <BalanceHistoryTable/>
      </div>
    </div>

  </div>
</template>

<script>
  import Breadcrumb from "../../templates/Breadcrumb/Breadcrumb.vue";
  import CardLeftBlock from "../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import BalanceHistoryTable from "./components/BalanceHistoryTable/BalanceHistoryTable";
  import SearchEngine from "../../coreComponents/SearchEngine/SearchEngine";

  export default {
    name: "BalanceHistoryModule",

    components:{
      SearchEngine,
      Breadcrumb,
      CardLeftBlock,
      BalanceHistoryTable,
    },

    data(){
      return {

      }
    },

  }
</script>

<style scoped>

  @media (min-width: 1220px) {
    .change-balance-line:first-child {
      margin-top: 20px;
    }
  }
</style>
