<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div>
          <div v-if="item.order_id">
            <template v-if="item.orderInstance && item.orderInstance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.id">
              <router-link  class="table-card__item-number"
                           :to="`${$store.getters.GET_PATHS.expressOrderShow}/fedex/${item.order_id}`">
                #{{item.order_id}}
              </router-link>
            </template>
            <template v-else-if="item.orderInstance && item.orderInstance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.id">
              <router-link  class="table-card__item-number"
                           :to="`${$store.getters.GET_PATHS.expressOrderShow}/tnt/${item.order_id}`">
                #{{item.order_id}}
              </router-link>
            </template>
            <template v-else-if="item.orderInstance && item.orderInstance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id">
              <router-link  class="table-card__item-number"
                           :to="`${$store.getters.GET_PATHS.expressOrderShow}/dhl/${item.order_id}`">
                #{{item.order_id}}
              </router-link>
            </template>
            <template v-else-if="item.orderInstance && item.orderInstance.delivery_service_id === INTERNAL_DELIVERY_SERVICES.NOVAPOSHTA_DELIVERY_SERVICE.id">
              <router-link  class="table-card__item-number"
                           :to="`${$store.getters.GET_PATHS.novaPoshta}/show/${item.order_id}`">
                #{{item.order_id}}
              </router-link>
            </template>
            <template v-else-if="item.orderInstance && item.orderInstance.delivery_service_id === INTERNAL_DELIVERY_SERVICES.CDEK_DELIVERY_SERVICE.id">
              <router-link  class="table-card__item-number"
                           :to="`${$store.getters.GET_PATHS.cdek}/show/${item.order_id}`">
                #{{item.order_id}}
              </router-link>
            </template>
            <template v-else>
              <div  class="table-card__item-number">
                #{{item.order_id}}
              </div>
            </template>
          </div>

          <span v-if="!item.order_id">
            <template v-if="_.has(item.payment_transaction, 'payment_system') &&
              item.payment_transaction.payment_system === CUSTOMERS_PAYMENTS_STATUSES.SYSTEM_TRANSACTION_STATUS">
              <div class="table-row payment-comment mb-2"
                v-if="item.payment_transaction && item.payment_transaction.payment_transaction_detail &&
                item.payment_transaction.payment_transaction_detail.description"
              >
                <ValueHelper
                        :value="item"
                        :deep="'payment_transaction.payment_transaction_detail.description'"
                />
              </div>
               <div v-else class="table-row payment-comment">
                <div class="table-card__item-number">
                 &mdash;
                </div>
               </div>
            </template>
            <template v-else>
              {{item.comment}}
            </template>
          </span>
        </div>
<!--        <span>-->
<!--          <div class="table-card__item-number" v-if="item.payment_type === 'remove_money'">-->
<!--            #{{item.order_id}}-->
<!--          </div>-->
<!--          <span class="table-card__item-number" v-if="item.order_id">-->
<!--            <template v-if="item.orderInstance && item.orderInstance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.id">-->
<!--              <router-link class="table-link btn-style"-->
<!--                           :to="`${$store.getters.GET_PATHS.expressOrderShow}/fedex/${item.order_id}`">-->
<!--                #{{item.order_id}}-->
<!--              </router-link>-->
<!--            </template>-->
<!--            <template v-else-if="item.orderInstance && item.orderInstance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.id">-->
<!--              <router-link class="table-link btn-style"-->
<!--                           :to="`${$store.getters.GET_PATHS.expressOrderShow}/tnt/${item.order_id}`">-->
<!--                #{{item.order_id}}-->
<!--              </router-link>-->
<!--            </template>-->
<!--            <template v-else-if="item.orderInstance && item.orderInstance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id">-->
<!--              <router-link class="table-link btn-style"-->
<!--                           :to="`${$store.getters.GET_PATHS.expressOrderShow}/dhl/${item.order_id}`">-->
<!--                #{{item.order_id}}-->
<!--              </router-link>-->
<!--            </template>-->
<!--            <template v-else-if="item.orderInstance && item.orderInstance.delivery_service_id === INTERNAL_DELIVERY_SERVICES.NOVAPOSHTA_DELIVERY_SERVICE.id">-->
<!--              <router-link class="table-link btn-style"-->
<!--                           :to="`${$store.getters.GET_PATHS.novaPoshta}/show/${item.order_id}`">-->
<!--                #{{item.order_id}}-->
<!--              </router-link>-->
<!--            </template>-->
<!--            <template v-else-if="item.orderInstance && item.orderInstance.delivery_service_id === INTERNAL_DELIVERY_SERVICES.CDEK_DELIVERY_SERVICE.id">-->
<!--              <router-link class="table-link btn-style"-->
<!--                           :to="`${$store.getters.GET_PATHS.cdek}/show/${item.order_id}`">-->
<!--                #{{item.order_id}}-->
<!--              </router-link>-->
<!--            </template>-->
<!--            <template v-else>#{{item.order_id}}</template>-->
<!--          </span>-->

<!--          <span v-else style="font-size: 20px">-->
<!--            &mdash;-->
<!--          </span>-->
<!--        </span>-->
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt" style="text-transform: capitalize;">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col ">
            <div class="table-card__item-label">
              {{$t('payments_transactionAmount.localization_value.value')}}, $
            </div>
            <div class="table-card__item-info">
              <template v-if="item.payment_type === 'remove_money'">-</template>
              $ {{item.amount | number('0.00')}}
            </div>
          </div>
          <div class="table-card__item-col ">
            <div class="table-card__item-label">
              {{$t('payments_userBalance.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              $ {{item.user_balance}}
            </div>
          </div>
          <div class="table-card__item-col ">
            <div class="table-card__item-label">
              {{$t('payments_type.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <span v-if="item.payment_type === 'add_money'">
                $ {{$t('payments_moneyEarned.localization_value.value')}}
              </span>
              <span v-if="item.payment_type === 'remove_money'">
                $ {{$t('payments_moneyWithdraw.localization_value.value')}}
              </span>
            </div>
          </div>
          <div class="table-card__item-col ">
            <div class="table-card__item-label">
              {{$t('payments_comment.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="fromInvoice(item) || fromOutput(item)">
                <BalanceHistoryTableComment
                    :item="item"
                    :orderInstance="item.orderInstance"
                    :payedInvoice="item.payed_invoice"
                />
              </template>
              <template v-else-if="_.has(item.payment_transaction, 'payment_system') &&
                item.payment_transaction.payment_system === CUSTOMERS_PAYMENTS_STATUSES.SYSTEM_TRANSACTION_STATUS">
                <div class="table-row payment-comment">
                  <template v-if="item.comment.indexOf('SkladUSA Fee - Id') > -1">
                    {{$t('payments_SkladUSAFee.localization_value.value')}}
                  </template>
                  <TooltipTextHelper v-else>
                    <template slot="text">
                      <ValueHelper
                          :value="item"
                          :deep="'payment_transaction.payment_transaction_detail.description'"
                      />
                    </template>
                    <template slot="paragraph">
                      <ValueHelper
                          :value="item"
                          :deep="'payment_transaction.payment_transaction_detail.description'"
                      />
                    </template>
                  </TooltipTextHelper>
                </div>
              </template>
              <template v-else-if="fromPayPalCase(item)">
                {{$t('payments_complaintRequestId.localization_value.value')}}:
                <router-link class="table-link btn-style"
                             :to="`${$store.getters.GET_PATHS.financePayPalCases}?page=1&count=25&complaintId=${item.order_id}`">
                  <ValueHelper
                      :value="item"
                      :deep="'order_id'"
                  />
                </router-link>
              </template>
              <template v-else>
                {{item.comment}}
              </template>
            </div>
          </div>
          <div class="table-card__item-col ">
            <div class="table-card__item-label">
              {{$t('payments_transaction.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="_.has(item.payment_transaction, 'payment_system') &&
                item.payment_transaction.payment_system === CUSTOMERS_PAYMENTS_STATUSES.SYSTEM_TRANSACTION_STATUS">
                <router-link
                    class="site-link"
                    :to="$store.getters.GET_PATHS.cusPayments + '?type=other&order=' + item.payment_transaction_id">
                  #{{item.payment_transaction_id}}
                </router-link>
              </template>

              <template v-else-if="_.has(item.payment_transaction, 'payment_system') &&
                item.payment_transaction.payment_system === PAYMENT_TYPE_NAME.authorize">
                <router-link
                    class="site-link"
                    :to="getRouteBySluiceType(item.payment_transaction) + `?type=${PAYMENT_TYPE_NAME.authorize}&order=` + item.payment_transaction_id">
                  #{{item.payment_transaction_id}}
                </router-link>
              </template>
              <template v-else-if="_.has(item.payment_transaction, 'payment_system') &&
                item.payment_transaction.payment_system === PAYMENT_TYPE_NAME.payPall">
                <router-link
                    class="site-link"
                    :to="getRouteBySluiceType(item.payment_transaction) + `?type=${PAYMENT_TYPE_NAME.payPall}&order=` + item.payment_transaction_id">
                  #{{item.payment_transaction_id}}
                </router-link>
              </template>

              <template v-else>
                <ValueHelper
                    v-if="item.payment_transaction"
                    :value="item.payment_transaction.transaction_id"/>
                <template v-else>&mdash;</template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
import {
  CUSTOMERS_PAYMENTS_STATUSES,
  EXPRESS_DELIVERY_SERVICES,
  INTERNAL_DELIVERY_SERVICES, PAYMENT_TYPE_NAME, TRANSACTION_SLUICE_TYPES
} from "../../../../../../../../staticData/staticVariables";
import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
import BalanceHistoryTableComment
  from "@/components/modules/BalanceHistoryModule/components/BalanceHistoryTable/BalanceHistoryTableUser/BalanceHistoryTable/BalanceHistoryTableComment/BalanceHistoryTableComment";

  export default {
    name: "BalanceHistoryTableMobile",

    components: {
      BalanceHistoryTableComment,
      TooltipTextHelper,
      ValueHelper

    },

    props: {
     item: Object
    },

    data() {
      return {
        show: false,

        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
        EXPRESS_DELIVERY_SERVICES: EXPRESS_DELIVERY_SERVICES,
        INTERNAL_DELIVERY_SERVICES: INTERNAL_DELIVERY_SERVICES,
        PAYMENT_TYPE_NAME: PAYMENT_TYPE_NAME,
      }
    },

    methods: {
      getRouteBySluiceType(item) {
        if(item.transaction_sluice === TRANSACTION_SLUICE_TYPES.INTERNAL)
          return this.$store.getters.GET_PATHS.financeMyPayments

        return this.$store.getters.GET_PATHS.cusPayments
      },

      fromPayPalCase(item) {
        return item.orderInstance && item.orderInstance?.complaint_type_id
      },

      fromInvoice(item) {
        return item.invoice
      },

      hasInvoiceInstance(item){
        return item?.invoice?.invoice_instance
      },

      fromOutput(item) {
        return item.orderInstance && item.orderInstance.balance_before_output
      },
    }

  }
</script>

<style scoped>

</style>
