<template>
  <div class="fragment">

    <div class="content-top-line position-relative d-mt-0">
      <div class="content-tabs content-tabs--empty"></div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

  </div>
</template>

<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";

  export default {
    name: "BalanceHistoryHead",
    components:{
      ToggleFilterButton,
    },

    props: [
      'showFilter', 'typeShops', 'countFilterParams'
    ],

    watch: {
      // countFilterParams: (newVal) => {
      //   this.countFilterParams = newVal
      // }
    },

    created() {

    },

    data() {
      return {

      }
    },



    methods: {

    }
  }
</script>

<style scoped>

  .content-top-line{
    min-height: 30px;
  }

  @media(min-width: 1600px){
    .d-mt-0{
      margin-top: -30px;
    }
  }

  @media(min-width: 550px) and (max-width: 1599px){
    .d-mt-0{
      margin-top: 0;
    }
  }
  @media(max-width: 550px){
    .d-mt-0{
      margin-top: 15px;
    }
  }

</style>
